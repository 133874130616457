import React from "react";

// Layout
import Layout from "../components/layout";
import Contain from "../components/contain";
import Content from "../components/content";
import HeroPage from "../components/hero-page";
import Heading from "../components/heading";
import Cta from "../components/cta";
import Seo from "../components/seo";

export default ({ location }) => {
  return (
    <Layout location={location}>
      <Seo
        title="Privacy | Quickpage"
        description="Follow up better. Close deals faster. With video."
        keywords="video, email, video email"
        url="https://kintz.quickpage.io/privacy"
      />

      <HeroPage>
        <Contain>
          <Heading textCenter>Privacy Policy</Heading>
        </Contain>
      </HeroPage>

      <Contain>
        <Content>
          <p>
            QUICKPAGE INC. TAKES YOUR PRIVACY AND THE PROTECTION OF YOUR
            PERSONAL INFORMATION SERIOUSLY. QUICKPAGE PROVIDES THIS PRIVACY
            POLICY TO HELP YOU MAKE AN INFORMED DECISION ABOUT WHETHER TO USE OR
            CONTINUE USING QUICKPAGE’S APPLICATION AND RELATED MATERIALS,
            SOFTWARE, AND CONTENT (“APP SERVICES”). IF YOU DO NOT AGREE TO OUR
            PRACTICES, PLEASE DO NOT USE THE APP SERVICES.
          </p>
          <p>
            This Privacy Policy is incorporated into and subject to Quickpage’s
            Terms of Service (“Terms”). Your use of the App Services, and any
            personal information you provide as a result of your access to and
            use of the App Services, remains subject to the terms of this
            Privacy Policy and our Terms.
          </p>
          <p>
            Please note that any User Submissions or other content posted at the
            direction or discretion of users of the App Services become
            published content and are not considered personally identifiable
            information subject to this Privacy Policy.
          </p>
          <p>
            <strong>1. What does this Privacy Policy Cover?</strong>
          </p>
          <p>
            This Privacy Policy is part of Quickpage’s Terms of Service and
            covers the treatment of user information, including personally
            identifiable information, obtained by Quickpage, including
            information obtained when you access and use the App Services or
            other software provided by Quickpage. This Privacy Policy does not
            apply to the practices of companies that Quickpage does not own or
            control, or to individuals whom Quickpage does not employ or manage,
            including any of the third parties to which Quickpage may disclose
            user information as set forth in this Privacy Policy.
          </p>
          <p>
            <strong>2. The Information Quickpage Collects</strong>
          </p>
          <p>
            Quickpage may obtain the following types of information from or
            concerning you or your mobile phone device, which may include
            information that can be used to identify you, as specified below
            (“Personally Identifying Information” or “PII”).
          </p>
          <p>
            <em>User Provided Information</em>: You provide certain PII, such as
            your name, mobile phone number, billing information (if applicable),
            and email address, when choosing to participate in various uses of
            the App Services, such as registering as a user. As an example, if
            you wish to join a chat regarding content created by another user,
            you will be requested to input your name and email address in order
            to proceed. If you are using the App Services to create pages, after
            a free trial, you will be given the opportunity to purchase further
            use, which will require provision of billing and credit card
            information.
          </p>
          <p>
            <em>Cookies Information</em>: When you use Quickpage’s mobile
            application, we may send one or more cookies, or small text files
            containing a sting of alphanumeric characters, to your mobile device
            or computer that uniquely identify your browser. Quickpage uses both
            session cookies and persistent cookies. A persistent cookie remains
            after you close your browser and may be used by your browser on
            subsequent visits to the application. Persistent cookies can be
            removed by following your web browser’s help file directions. A
            session cookie is temporary and disappears after you close your
            browser. You can reset your web browser to refuse all cookies or to
            indicate when a cookie is being sent. However, the App Services may
            not function properly if the ability to accept cookies is disabled.
          </p>
          <p>
            <em>Log File Information</em>: As part of the standard operation of
            the App Services, Quickpage may collect and analyze information when
            you use the application. Our servers automatically record certain
            information that your web browser sends us. These server logs may
            include information such as your web request, Internet Protocol
            (“IP”) address, browser type and language, referring/exit pages and
            URLs, platform type, number of clicks, domain names, landing pages,
            pages viewed and the order of those pages, the amount of time spent
            on particular pages, conversations within chats, and the date and
            time of your request. In essence, Quickpage will be analyzing the
            use of its applications and App Services.
          </p>
          <p>
            <strong>3. Information Quickpage does not Collect</strong>
          </p>
          <p>
            Quickpage does not collect physical addresses or other contact
            information from its users, its users’ mobile address books, or its
            users’ contact lists. We do not collect GSP location data, but users
            may voluntarily share their location with other users via the App
            Services. We do, however, share your city and state-level location
            information with other users of the App Services.
          </p>
          <p>
            <strong>4. Uses and Disclosures of Information Collected</strong>
          </p>
          <p>
            If you submit PII to us through your access to or use of the App
            Services, we will use your personal information to operate,
            maintain, and provide to you the features and functionality of the
            App Services. In particular, your name and email address are
            essential to your use of the App Services and will be retained.
            Credit card information will used only for billing and will not be
            stored in Quickpage’s servers. Billing will be facilitated through
            Stripe, a payment processing service. For further information about
            Stripe and to read its Terms of Service and Privacy Policy, click{" "}
            <a href="https://stripe.com/us/terms">here</a>. In addition, any PII
            or content you voluntarily disclose on the App Services becomes
            publicly available and may be collected and used by other users of
            the App Services. It is important that you take caution before
            voluntarily disclosing such information. Your name and city and
            state-level location information may be displayed to other users
            when you use the App Services, especially when you go to a page and
            join a chat. We may use your PII without further consent to send
            commercial or marketing messages as part of a specific program or
            feature for which you will have the ability to opt in or out. We may
            use your email address without further consent for non-marketing or
            administrative purposes, such as notifying you of major changes to
            Quickpage and its App Services. We may use both your PII and certain
            non-personally identifiable information (such as anonymous user
            usage data, cookies, IP addresses, browser type, clickstream data,
            etc.) to improve the quality, design, and functionality of the App
            Services, and to create new features, promotions, and services by
            storing, tracking, and analyzing user preferences and trends. We may
            use cookies and log file information to provide custom, personalized
            content and information, monitor individual and aggregate metrics
            such as total number of visitors or pages viewed, or track entries,
            submissions, and views. Quickpage may use third party cookies on the
            App Services as well. For instance, Quickpage uses Google Analytics
            to collect and process certain analytics data. Google provides some
            additional privacy options described at{" "}
            <a href="https://www.google.com/policies/privacy/partners/">
              www.google.com/policies/privacy/partners/
            </a>{" "}
            regarding Google Analytics cookies.
          </p>
          <p>
            By providing Quickpage or a Quickpage customer with your mobile
            telephone number, you consent to receive text messages at that
            number as requested for account verification, invitations,
            marketing, and other purposes related to the App Services. While
            Quickpage does not charge a fee for text messages, your carrier may
            charge standard messaging, data, and other fees. You are responsible
            for these charges. Quickpage may send and receive text messages
            through cellular telephone operators or other networks, and the
            level of reliability may vary. Quickpage is not responsible for the
            timeliness or final delivery of the message, as this is out of
            Quickpage’s control and is the responsibility of the cellular
            telephone operator or other networks. You have the ability to opt
            out of receiving these SMS messages by texting the word STOP to{" "}
            <strong>425-240-9393</strong>. You may also reply STOP to any
            message you receive from Quickpage. Upon receipt of your STOP
            message, Quickpage will send you a SMS message to confirm that you
            have been unsubscribed. At that point, you will no longer receive
            any further SMS messages from Quickpage. If you need any assistance,
            you can always text the word HELP to <strong>425-240-9393</strong>{" "}
            or reply "HELP" to any message you receive from Quickpage.
          </p>
          <p>
            <strong>5. When Quickpage Discloses Information</strong>
          </p>
          <p>
            Other users of the App Services may see your User Submissions (for
            example, content you post in a chat) in a way that is consistent
            with proper use of the App Services. We do not sell or share your
            PII with other third parties for their commercial or marketing use
            without your consent or as part of a specific program or feature for
            which you will have the ability to opt in or out. We may share your
            PII with third party service providers to the extent it is
            reasonably necessary to perform, improve, or maintain the App
            Services. We may share non-personally identifiable information (such
            as anonymous user usage data or number of clicks) with interested
            third parties to assist them in understanding the usage patterns for
            certain content, services, advertisements, promotions, and/or
            functionality of the App Services. We may collect and release PII
            and/or non-personally identifiable information if required to do so
            by law, or in the good-faith belief that such action is necessary to
            comply with state and federal laws, international law, or to respond
            to a court order, subpoena, search warrant, or the equivalent, or
            where in our reasonable belief, an individual’s physical safety may
            be at risk. Quickpage also reserves the right to disclose PII and/or
            non-personally identifiable information that Quickpage believes, in
            good faith, is appropriate or necessary to enforce the Terms of
            Service, to take precautions against liability, to investigate and
            defend itself against third party claims or allegations, to assist
            government enforcement agencies, to protect the security or
            integrity of the App Services or our servers, and to protect the
            rights, property, and personal safety of Quickpage, our users, and
            others.
          </p>
          <p>
            <strong>6. Your Choices</strong>
          </p>
          <p>
            You may decline to submit Personally Identifiable Information
            through the App Services, in which case Quickpage may not be able to
            provide certain services to you. If you do not agree with our
            Privacy Policy or Terms of Service, please cease use of the App
            Services and uninstall the mobile application. Your continued use of
            the App Services signifies your assent to and acceptance of our
            Privacy Policy and Terms of Service. Please contact Quickpage via
            email with any questions or concerns about the Privacy Policy, your
            personal information, your consent, or your ability to opt in or
            out, if applicable.
          </p>
          <p>
            <strong>7. Public Forums</strong>
          </p>
          <p>
            We may off chat rooms, message boards, or other similar public
            forums where you and other users can communicate. The protections
            described in this Privacy Policy do not apply when you provide
            information (including personal information) in connection with your
            use of these public forums. We may use personally identifiable
            information, such as your name, to identify you with a posting in a
            public forum. Any information you share in a public forum is public
            information and may be seen or collected by anyone, including third
            parties that do not adhere to our Privacy Policy. We are not
            responsible for events arising from the distribution of any
            information you choose to publicly post or share through the App
            Services.
          </p>
          <p>
            <strong>8. Children’s Privacy</strong>
          </p>
          <p>
            Protecting the privacy of young children is especially important.
            The App Services are not directed to children under the age of 18,
            and Quickpage does not knowingly collect personal information from
            children under the age of 18 without obtaining parental consent.{" "}
          </p>
          <p>
            <strong>9. Our Commitment to Data Security</strong>
          </p>
          <p>
            Quickpage uses commercially reasonable physical, managerial, and
            technical safeguards to preserve the integrity and security of your
            personal information. We cannot, however, ensure or warrant the
            security of any information you transmit to Quickpage. Such
            transmissions are made at your own risk. Using unsecured Wi-Fi or
            other unprotected networks to submit messages through the App
            Services is not recommended. Once we receive your transmission of
            information, Quickpage uses commercially reasonable efforts to
            ensure the security of our systems. You acknowledge and understand,
            however, that this is not a guarantee that such information may not
            be accessed, disclosed, altered, or destroyed by breach of any of
            our safeguards. If Quickpage learns of a security systems breach, we
            may attempt to notify you electronically so you can take appropriate
            protective measures. Quickpage may also post a notice on the App
            Services if a security breach occurs.
          </p>
          <p>
            <strong>10. International Visitors</strong>
          </p>
          <p>
            The App Services are hosted in the United States. If you choose to
            use the App Services from the European Union or other regions of the
            world with laws governing data collection and use that may differ
            from U.S. law, then please note that you are transferring your
            personal information outside of those regions to the United States
            for storage and processing. Also, Quickpage may transfer your data
            from the U.S. to other countries or regions in connection with
            storage and processing of data, fulfilling your requests, and
            operating the App Services. By providing any information, including
            personal information, on or to the App Services, you consent to such
            transfer, storage, and processing.
          </p>
          <p>
            <strong>11. Effect of Merger, Sale, or Bankruptcy</strong>
          </p>
          <p>
            In the event Quickpage is acquired by or merged with a third party
            entity, we reserve the right to transfer or assign the information
            we have collected from our users as part of such merger,
            acquisition, sale, or other change of control. In the unlikely event
            of our bankruptcy, insolvency, reorganization, receivership, or
            assignment for the benefit of creditors, or the application of laws
            or equitable principles affecting creditors’ rights generally, your
            information may be transferred to one or more third parties as one
            of our business assets.
          </p>
          <p>
            <strong>12. Changes and Updates to this Privacy Policy</strong>
          </p>
          <p>
            This Privacy Policy may be revised periodically and such revisions
            will be reflected by the “effective date” below. Please revisit this
            page to stay aware of any changes. If Quickpage modifies this
            Privacy Policy, it will make it available through the App Services,
            and indicate the date of the latest revision. In the event that the
            modifications materially alter your rights or obligations hereunder,
            Quickpage will make reasonable efforts to notify you of the change.
            For example, Quickpage may send a message to your email address, if
            it has one on file, or generate a pop-up or similar notification
            when you access the App Services for the first time after such
            material changes are made. Your continued use of the App Services
            after the revised Privacy Policy has become effective indicates that
            you have read, understood and agreed to the current version of the
            Privacy Policy.
          </p>
          <p>
            <strong>Date Last Modified: 25 January, 2017</strong>
          </p>
        </Content>
      </Contain>

      <Cta location={location} />
    </Layout>
  );
};
